<template>
	<div class="views-agreement" v-html="html"></div>
</template>

<script>
import { AgreementInfo } from '@/common/api.js';
export default {
	data() {
		return {
			html: '',
			type: ''
		};
	},
	created() {
		if (this.$route.query.type) {
			this.type = this.$route.query.type;
		}
		this.getAgreementInfo();
	},
	methods: {
		getAgreementInfo() {
			let data = {
				type: this.type
			};
			AgreementInfo(data).then((res) => {
				this.html = res.data;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.views-agreement {
	padding: 20px;
}
</style>
